import { config } from './config.js';
import { routing } from './functions/routing.js';
import { changeURL } from './functions/changeURL.js';
import { parserCSS } from './functions/parserCSS.js';
import { createCSS } from './functions/createCSS.js';
import { convertColor } from './functions/convertColor.js';
import { findColor } from './functions/findColor.js';
import { createHTML } from './functions/createHTML.js';
import { setPageScroll } from './functions/setPageScroll.js';
import { getPageScroll } from './functions/getPageScroll.js';
import { processPendingTasks } from './functions/processPendingTasks.js';
import { querySelectorAllDeep } from './functions/querySelectorAllDeep.js';
import { postData } from './functions/postData.js';

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { LitElement, html, render } from 'lit';

import variablesData from './document.json';
import designData from './design.json';

export const colors = JSON.parse(JSON.stringify(variablesData.colorVariables));
export const design = JSON.parse(JSON.stringify(designData.layers));

window.navigatorOnline = true;
window.addEventListener('online', () => {
    window.navigatorOnline = true;
});
window.addEventListener('offline', () => {
    window.navigatorOnline = false;
});

// Без этого события не срабатывают события нажатий внутри компонентов
document.addEventListener('touchstart', function (event) {
    event.preventDefault();
}, false);
// iOS запрет на масштабирование двойным тапом
document.ondblclick = function (event) {
    event.preventDefault();
}

// Ищем номер стола в URL и записываем в sessionStorage
const urlParams = new URLSearchParams(window.location.search);
const table = urlParams.get('table');
if (table) {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + config.tableLifetime);
    const tableDate = `{"table":"${table}","expires":"${currentDate.toISOString()}"}`;
    sessionStorage.setItem('table', tableDate);

    changeURL(window.location.pathname, 'keepScroll', '');
}

routing();
/* window.onpopstate = function () {
    routing();
}; */

/* window.addEventListener('popstate', function (event) {
    console.log(event);
    routing();
}); */

let currentDate = new Date().toISOString();
if (history.length <= 1) {
    history.replaceState({ date: currentDate, url: window.location.pathname, animation: 'default' }, null, window.location.pathname);
}
let currentPage = sessionStorage.getItem('navigation') ? JSON.parse(sessionStorage.getItem('navigation')) : null;
if (!currentPage && window.history.state) {
    sessionStorage.setItem('navigation', JSON.stringify(window.history.state));
}
setPageScroll(window.location.pathname);


// Обработчик popstate
window.addEventListener('popstate', function (event) {
    let currentPage = sessionStorage.getItem('navigation') ? JSON.parse(sessionStorage.getItem('navigation')) : null;

    if (event.state) {
        if (currentPage.date > event.state.date) {
            //console.log('Back');

            if (document.documentElement.clientWidth < '800') {
                if (currentPage.animation === 'slidein') {
                    // slideout
                    getPageScroll(currentPage.url);
                    document.getElementById("app").style.cssText = `opacity: 0; margin-left: ${document.documentElement.clientWidth / 3}px; transition: opacity 200ms linear, margin 200ms cubic-bezier(0.895, 0.030, 0.685, 0.220);`;
                    setTimeout(function () {
                        document.getElementById("app").style.cssText = `opacity: 0; margin-left: -${document.documentElement.clientWidth / 8}px;`;
                        routing();
                        getPageScroll(event.state.url);
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("app").style.cssText = `opacity: 1; margin-left: 0px; transition: opacity 300ms linear, margin 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000);`;
                    }, 250);
                    setTimeout(function () {
                        document.getElementById("app").removeAttribute("style");
                    }, 550);
                } else if (currentPage.animation === 'slideup') {
                    // slidedown
                    getPageScroll(currentPage.url);
                    document.getElementById("app").style.cssText = `opacity: 0; margin-top: ${document.documentElement.clientHeight / 9}px; transition: opacity 300ms linear, margin 300ms cubic-bezier(0.895, 0.030, 0.685, 0.220);`;
                    setTimeout(function () {
                        document.getElementById("app").style.cssText = `opacity: 0; margin-top: -${document.documentElement.clientHeight / 18}px;`;
                        routing();
                        getPageScroll(event.state.url);
                    }, 300);
                    setTimeout(function () {
                        document.getElementById("app").style.cssText = `opacity: 1; margin-top: 0px; transition: opacity 300ms linear, margin 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000);`;
                    }, 350);
                    setTimeout(function () {
                        document.getElementById("app").removeAttribute("style");
                    }, 650);
                } else {
                    routing();
                }
            } else {
                routing();
            }
        } else if (currentPage.date < event.state.date) {
            //console.log('Next');

            if (document.documentElement.clientWidth < '800') {
                if (event.state.animation === 'slidein') {
                    getPageScroll(currentPage.url);
                    document.getElementById("app").style.cssText = `opacity: 0; margin-left: ${document.documentElement.clientWidth / 3}px;`;
                    routing();
                    getPageScroll(event.state.url);
                    setTimeout(function () {
                        document.getElementById("app").style.cssText = "opacity: 1; margin-left: 0px; transition: opacity 400ms linear, margin 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);";
                    }, 10);
                    setTimeout(function () {
                        document.getElementById("app").removeAttribute("style");
                    }, 410);
                } else if (event.state.animation === 'slideup') {
                    getPageScroll(currentPage.url);
                    document.getElementById("app").style.cssText = `opacity: 0; margin-top: ${document.documentElement.clientHeight / 9}px;`;
                    routing();
                    getPageScroll(event.state.url);
                    setTimeout(function () {
                        document.getElementById("app").style.cssText = "opacity: 1; margin-top: 0px; transition: opacity 400ms linear, margin 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);";
                    }, 10);
                    setTimeout(function () {
                        document.getElementById("app").removeAttribute("style");
                    }, 410);
                } else {
                    routing();
                }
            } else {
                routing();
            }
        } else {
            routing();
        }
        sessionStorage.setItem('navigation', JSON.stringify(event.state));
    }

});

// Парсинг и выполнение скриптов
design.forEach(item => {
    if (item._t === "TEXT" && item.name === 'js') {
        (new Function(item.text))();
    }
});

function parsePage(page, pageSize, state, rerender, renderState) {
    let template = null;
    if (!state) {
        state = 'view';
    }

    // Найти соответствующий элемент для light темы
    let designData = design.find(item =>
        item._t == "FRAME" &&
        item.name.split('/')[0] == pageSize &&
        item.name.split('/')[1] == page &&
        item.name.split('/')[2] == state
    ) || design.find(item =>
        item._t == "FRAME" &&
        item.name.split('/')[0] == 'mobile' &&
        item.name.split('/')[1] == page &&
        item.name.split('/')[2] == state
    );

    if (designData) {

        designData.fills ? designData.fills.map(fill => {
            document.documentElement.style.setProperty('--page-background', fill.color ? convertColor(fill.color) : fill.colorId ? findColor(fill.colorId, 'light') : '');
            if (config.darkTheme) {
                document.documentElement.style.setProperty('--page-background-dark', fill.color ? convertColor(fill.color) : fill.colorId ? findColor(fill.colorId, 'dark') : '');
            }
        }).join('') : '';

        template = html`
            <style>
                :host {
                    ${parserCSS(designData)}
                    width: 100%;
                    height: 100%;
                    min-width: 100%;
                    min-height: 100dvh;
                }
                ${Array.isArray(designData.layers) ? `${designData.layers.map(layer => createCSS(layer, `${designData.container ? `${designData.container.orientation == 1 ? 'column' : designData.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host')).join('')}` : ''}
                ${config.darkTheme ? `
                    @media (prefers-color-scheme: dark) {
                        :host {
                            ${parserCSS(designData, '', 'dark')}
                            width: 100%;
                            height: 100%;
                            min-width: 100%;
                            min-height: 100dvh;
                        }
                        ${Array.isArray(designData.layers) ? `${designData.layers.map(layer => createCSS(layer, `${designData.container ? `${designData.container.orientation == 1 ? 'column' : designData.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host', 'dark')).join('')}` : ''}
                    }
                ` : ''}
            </style>
            ${Array.isArray(designData.layers) ? designData.layers.map(layer => createHTML(layer, '', '', '', '', rerender, renderState)) : ''}
        `;
    }
    if (!template) { // если шаблон не изменился, возвращаем 404 Not Found
        template = html`<div>404 Not Found</div>`;
    }
    return template;
}

/* function createPages(rerender) {
    design.forEach(page => {
        if (page._t == "FRAME") {
            let template1 = null;
            let template2 = null;

            let partName = page.name.split('/');

            let pageSize = partName[1];
            let pageName = partName[2];
            let pageState = partName[3];
            let renderstate = false;

            let templateKey = pageSize + pageName + pageState;

            // Найти соответствующий элемент для light темы
            let designData = design.find(item =>
                item._t == "FRAME" &&
                item.name.split('/')[0] == 'light' &&
                item.name.split('/')[1] == pageSize &&
                item.name.split('/')[2] == pageName &&
                item.name.split('/')[3] == pageState
            ) || design.find(item =>
                item._t == "FRAME" &&
                item.name.split('/')[0] == 'light' &&
                item.name.split('/')[1] == 'mobile' &&
                item.name.split('/')[2] == pageName &&
                item.name.split('/')[3] == pageState
            );

            // Найти соответствующие элементы для dark темы
            let darkTheme = design.find(item =>
                item._t == "FRAME" &&
                item.name.split('/')[0] == 'dark' &&
                item.name.split('/')[1] == pageSize &&
                item.name.split('/')[2] == pageName &&
                item.name.split('/')[3] == pageState
            ) || design.find(item =>
                item._t == "FRAME" &&
                item.name.split('/')[0] == 'dark' &&
                item.name.split('/')[1] == 'mobile' &&
                item.name.split('/')[2] == pageName &&
                item.name.split('/')[3] == pageState
            );

            if (designData) {
                template1 = html`
                    <style>
                        :host {
                            ${parserCSS(designData)}
                            width: 100%;
                            height: 100%;
                            min-width: 100%;
                            min-height: 100dvh;
                        }
                        ${Array.isArray(designData.layers) ? `${designData.layers.map(layer => createCSS(layer, `${designData.container ? `${designData.container.orientation == 1 ? 'column' : designData.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host')).join('')}` : ''}
                        ${darkTheme ? `
                            @media (prefers-color-scheme: dark) {
                                :host {
                                    ${parserCSS(darkTheme)}
                                    width: 100%;
                                    height: 100%;
                                    min-width: 100%;
                                    min-height: 100dvh;
                                }
                                ${Array.isArray(darkTheme.layers) ? `${darkTheme.layers.map(layer => createCSS(layer, `${darkTheme.container ? `${darkTheme.container.orientation == 1 ? 'column' : darkTheme.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host')).join('')}` : ''}
                            }
                        ` : ''}
                    </style>
                    ${Array.isArray(designData.layers) ? designData.layers.map(layer => createHTML(layer, '', '', '', '', rerender, false)) : ''}
                `;
                pageTemplates.set(templateKey + 'false', template1);
                template2 = html`
                    <style>
                        :host {
                            ${parserCSS(designData)}
                            width: 100%;
                            height: 100%;
                            min-width: 100%;
                            min-height: 100dvh;
                        }
                        ${Array.isArray(designData.layers) ? `${designData.layers.map(layer => createCSS(layer, `${designData.container ? `${designData.container.orientation == 1 ? 'column' : designData.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host')).join('')}` : ''}
                        ${darkTheme ? `
                            @media (prefers-color-scheme: dark) {
                                :host {
                                    ${parserCSS(darkTheme)}
                                    width: 100%;
                                    height: 100%;
                                    min-width: 100%;
                                    min-height: 100dvh;
                                }
                                ${Array.isArray(darkTheme.layers) ? `${darkTheme.layers.map(layer => createCSS(layer, `${darkTheme.container ? `${darkTheme.container.orientation == 1 ? 'column' : darkTheme.container.wrap == true ? 'wrap' : 'row'}` : 'row'}`, ':host')).join('')}` : ''}
                            }
                        ` : ''}
                    </style>
                    ${Array.isArray(designData.layers) ? designData.layers.map(layer => createHTML(layer, '', '', '', '', rerender, true)) : ''}
                `;
                pageTemplates.set(templateKey + 'true', template2);
            }
        }
    });
} */


/* function parsePage(url) {
    let template = '';
    let page = url.page;
    let navtab = url.navtab || '';
    let tab = url.tab || '';
    let state = url.state || 'view';
    console.log(url);

    design.filter(item => {
        if (item._t == "FRAME") {
            let props = JSON.parse(item.name.replace(/'/g, "\""));

            if (props.theme == 'light' && props.page == page && (!props.navtab || props.navtab == navtab) && (!props.tab || props.tab == tab) && props.state == state) {
                template = `
                    <style>
                        :host {
                            ${parserCSS(item)}
                            width: 100%;
                            height: 100%;
                            min-width: 100%;
                            min-height: 100dvh;
                        }
                        ${Array.isArray(item.layers) ? `${item.layers.map(layer => createCSS(layer, `
                            ${item.container.orientation == 1 || item.container.wrap == true ? 1 : 0}
                        `, ':host')).join('')}` : ''}

                        ${design.forEach(item => `
                            ${item._t == "FRAME" && props.theme == 'dark' && props.page == page && (!props.navtab || props.navtab == navtab) && (!props.tab || props.tab == tab) && props.state == state ? `
                                @media (prefers-color-scheme: dark) {
                                    :host {
                                        ${parserCSS(item)}
                                        width: 100%;
                                        height: 100%;
                                        min-width: 100%;
                                        min-height: 100dvh;
                                    }
                                    ${Array.isArray(item.layers) ? `${item.layers.map(layer => createCSS(layer, `
                                        ${item.container.orientation == 1 || item.container.wrap == true ? 1 : 0}
                                    `, ':host')).join('')}` : ''}
                                }
                            ` : ''}
                        `)}
                    </style>
                    ${Array.isArray(item.layers) ? `${item.layers.map(layer => createHTML(layer)).join('')}` : ''}
                `;
            }
        }
    });
    if (template === '') {
        template = `404 Not Found`;
    }
    return template;
} */

/* function parsePage(page, state) {
    let template = '';
    let data = '';
    if (!state) {
        state = 'view';
    }
    design.filter(item => {
        if (item._t == "FRAME") {
            let props = JSON.parse(item.name.replace(/'/g, "\""));
            if (props.type == 'page' && props.name == page && props.state == state && props.theme == 'light') {
                template = `
                    <style>
                        :host {
                            ${parserCSS(item)}
                            width: 100%;
                            height: 100%;
                            min-width: 100%;
                            min-height: 100dvh;
                            ${props?.style ? props.style : ''}
                        }
                        ${props?.globStyle ? props.globStyle : ''}
                        ${Array.isArray(item.layers) ? `${item.layers.map(layer => createCSS(layer, `
                            ${item.container.orientation == 1 || item.container.wrap == true ? 1 : 0}
                        `, ':host')).join('')}` : ''}

                        ${design.forEach(item => `
                            ${item._t == "FRAME" && JSON.parse(item.name.replace(/'/g, "\"")).type == 'page' && JSON.parse(item.name.replace(/'/g, "\"")).name == page && JSON.parse(item.name.replace(/'/g, "\"")).state == state && JSON.parse(item.name.replace(/'/g, "\"")).theme == 'dark' ? `
                                @media (prefers-color-scheme: dark) {
                                    :host {
                                        ${parserCSS(item)}
                                        width: 100%;
                                        height: 100%;
                                        min-width: 100%;
                                        min-height: 100dvh;
                                    }
                                    ${Array.isArray(item.layers) ? `${item.layers.map(layer => createCSS(layer, `
                                        ${item.container.orientation == 1 || item.container.wrap == true ? 1 : 0}
                                    `, ':host')).join('')}` : ''}
                                }
                            ` : ''}
                        `)}
                    </style>
                    ${Array.isArray(item.layers) ? `${item.layers.map(layer => createHTML(layer)).join('')}` : ''}
                `;
            }
        }
    });
    if (template === '') {
        template = `404 Not Found`;
    }
    return template;
} */

/* design.filter(item => {
    if (item._t == "FRAME") {
        let props = JSON.parse(item.name.replace(/'/g, "\""));

        if (props.type == 'page') {
            let className = 'page_' + props.name;
            window[className] = class extends HTMLElement {
                constructor() {
                    super();
                    this.attachShadow({ mode: 'open' });
                }

                connectedCallback() {
                    this.render();
                }

                render() {
                    this.shadowRoot.innerHTML = `
                        <style>
                            :host {
                                ${parserCSS(item)}
                                width: 100%;
                                height: 100%;
                                min-width: 100%;
                                min-height: 100dvh;
                            }
                            ${Array.isArray(item.layers) ? `${item.layers.map(layer => createCSS(layer, `
                                ${item.container.orientation == 1 || item.container.wrap == true ? 1 : 0}
                            `, ':host')).join('')}` : ''}
                        </style>
                        ${Array.isArray(item.layers) ? `${item.layers.map(layer => createHTML(layer)).join('')}` : ''}
                    `;
                }

                getPage(url, transition) {
                    let href = window.location.protocol + '//' + window.location.host + url;
                    let directories = url.split('/');
                    let directory = directories.slice(1);
                    history.pushState(directory[0], null, href);

                    //document.querySelector('app-layout').shadowRoot.querySelector('app-page').setAttribute('page', page);
                    //this.updateData('app-page', 'page', directory[0]);

                    let appPage = document.querySelector('app-layout').shadowRoot.querySelector('app-page');

                    if (document.documentElement.clientWidth < '880') {
                        switch (transition) {
                            case 'slidein':
                                appPage.style.cssText = "opacity: 0; transform: translateX(16%);";
                                updateData('[page]', 'page', directory[0]);
                                appPage.scrollTo(0, 1);
                                setTimeout(function () {
                                    appPage.style.cssText = "opacity: 1; transform: translateX(0%); transition: opacity 200ms ease-in, transform 200ms ease-in-out;";
                                }, 10);
                                setTimeout(function () {
                                    appPage.removeAttribute("style");
                                }, 210);
                                break;
                        }
                    } else { }
                }

            }
            const nameComponent = 'page-' + props.name;
            customElements.define(nameComponent, window[className]);
            //customElements.define(component.name.toLowerCase(), window[className]);

            //return obj.url === 'home';
            //return `<${nameComponent}></${nameComponent}`
        }
        //return obj.url;
        //console.log(obj);
    }
}); */

if (!customElements.get('app-page')) {
    class appPage extends LitElement {
        constructor() {
            super();
            this.attachShadow({ mode: 'open' });
            this.value = '';

            this.clickCount = 0;

            this.pageSize = 'mobile';
            this.widthTablet;
            this.widthDesktop;
        }

        connectedCallback() {
            super.connectedCallback();
            window.addEventListener('resize', this.updateWindowSize.bind(this));
            this.updateWindowSize(); // Инициализация начального состояния

            if (this.shadowRoot.clickHandler) {
                this.shadowRoot.removeEventListener('pointerup', this.shadowRoot.clickHandler);
            }
            this.shadowRoot.clickHandler = this.clickHandler;
            this.shadowRoot.addEventListener('pointerup', this.shadowRoot.clickHandler);            
        }

        disconnectedCallback() {
            super.disconnectedCallback();
            window.removeEventListener('resize', this.updateWindowSize.bind(this));

            this.shadowRoot.clickHandler = this.clickHandler;
            this.shadowRoot.removeEventListener('pointerup', this.shadowRoot.clickHandler);
        }

        static get observedAttributes() {
            return ['value'];
        }

        attributeChangedCallback(name, oldValue, newValue) {
            if (name === 'value') {
                this.value = newValue;
                this.clickCount = 0;
                this.customRender(false);
            }
        }

        updateWindowSize() {
            const width = document.documentElement.clientWidth;

            let newPageSize = this.pageSize;

            if (width < (this.widthTablet || this.widthDesktop || Infinity)) {
                newPageSize = 'mobile';
            } else if (
                (this.widthTablet && width >= this.widthTablet && width < (this.widthDesktop || Infinity)) ||
                (!this.widthTablet && this.widthDesktop && width < this.widthDesktop)
            ) {
                newPageSize = 'tablet';
            } else {
                newPageSize = 'desktop';
            }

            if (newPageSize !== this.pageSize) {
                this.pageSize = newPageSize;
                this.customRender(true);
            }
        }

        customRender = (updated) => {
            if (updated !== undefined) {

                let value = this.value ? this.value.split('/') : [];
                let directory = value.slice(1);
                let page = directory[0] || 'home';
                let state = 'view';
                if (directory[1] == 'view' || directory[1] == 'edit' || directory[1] == 'add') {
                    state = directory[1];
                } else if (directory[2] == 'view' || directory[2] == 'edit' || directory[2] == 'add') {
                    state = directory[2];
                }

                console.time('Render ' + page);

                this.widthTablet = design.find(item =>
                    item._t === "FRAME" &&
                    item.name.split('/')[0] === 'tablet' &&
                    item.name.split('/')[1] === page &&
                    item.name.split('/')[2] === state
                )?.size[0] || null;
                this.widthDesktop = design.find(item =>
                    item._t === "FRAME" &&
                    item.name.split('/')[0] === 'desktop' &&
                    item.name.split('/')[1] === page &&
                    item.name.split('/')[2] === state
                )?.size[0] || null;

                const width = document.documentElement.clientWidth;

                if (width < (this.widthTablet || this.widthDesktop || Infinity)) {
                    this.pageSize = 'mobile';
                } else if (
                    (this.widthTablet && width >= this.widthTablet && width < (this.widthDesktop || Infinity)) ||
                    (!this.widthTablet && this.widthDesktop && width < this.widthDesktop)
                ) {
                    this.pageSize = 'tablet';
                } else {
                    this.pageSize = 'desktop';
                }

                const template = html`${parsePage(page, this.pageSize, state, this.customRender, updated)}`;

                // Рендеринг шаблона в shadow DOM.
                render(template, this.shadowRoot);

                //setPageScroll(this.value);

                console.timeEnd('Render ' + page);
            }
        }

        clickHandler = event => {
            if (this.clickCount > 0) return;
            let element = event.target;
            while (element && !element.hasAttribute('clickevent') && !element.hasAttribute('href')) {
                element = element.parentElement;
            }
            if (!element) return;
            this.clickCount = +1;

            if (element.hasAttribute('clickevent')) {
                try {
                    const func = new Function(`return ${element.getAttribute('clickevent')}`);
                    func();
                } catch (error) {
                    return null; // возвращаем исходное value, что может быть не желаемым поведением
                }
            }

            const href = element.getAttribute('href');
            const transition = element.getAttribute('transition');
            const scroll_to = element.getAttribute('scrollto') || '';
            if (href) {
                changeURL(href, transition, scroll_to);
                event.preventDefault();
            } else {
                return;
            }

            setTimeout(() => {
                this.clickCount = 0;
            }, 300);
        };
    }
    customElements.define('app-page', appPage);
}

window.addEventListener('online', processPendingTasks);
processPendingTasks();

/* if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('./sw.js')
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((error) => {
                console.error('Service Worker registration failed:', error);
            });
    });
} */
/* if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register(
            new URL('sw.js', import.meta.url),
            { type: 'module' }
        )
        .then(registration => {
            console.log('Service Worker зарегистрирован:', registration);
            requestNotificationPermission();
        })
        .catch(error => {
            console.error('Ошибка при регистрации сервисного работника:', error);
        });
    });

    function requestNotificationPermission() {
        if ('Notification' in window) {
            Notification.requestPermission()
                .then(permission => {
                    if (permission === 'granted') {
                        console.log('Разрешение на уведомления предоставлено');
                    } else {
                        console.log('Разрешение на уведомления отклонено');
                    }
                })
                .catch(error => {
                    console.error('Ошибка при запросе разрешения:', error);
                });
        } else {
            console.log('Браузер не поддерживает API уведомлений');
        }
    }
}
 */

/* navigator.serviceWorker.register(
    new URL('sw.js', import.meta.url),
    { type: 'module' }
); */

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(new URL('sw.js', import.meta.url), { type: 'module', scope: '/' })
        .then((registration) => {
            console.log('Custom Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
            console.error('Custom Service Worker registration failed:', error);
        });

    navigator.serviceWorker.register(new URL('firebase-messaging-sw.js', import.meta.url), { type: 'module', scope: '/firebase/' })
        .then((registration) => {
            console.log('Firebase Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
            console.error('Firebase Service Worker registration failed:', error);
        });
}

const firebaseConfig = {
    apiKey: "AIzaSyBC2oRzu7Oq6dTPwrIr_D0PBwU-FWgQLzo",
    authDomain: "shareproto-3a54b.firebaseapp.com",
    projectId: "shareproto-3a54b",
    storageBucket: "shareproto-3a54b.appspot.com",
    messagingSenderId: "435410397573",
    appId: "1:435410397573:web:1432c7115aff9458493cec"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const obtainToken = async () => {
    try {
        const token = await getToken(messaging, { vapidKey: "BK2bt8nFoJ6OkTO9xdDBLE0MDGxFjxOfAsaHmucTTYUXUbKoBTRHZ6YZI-v0cvlp7rBlF-Q4MgHDBZqosIxVMK0" });
        if (token) {
            console.log('Device token:', token);
            return token;
        }
    } catch (error) {
        console.error('An error occurred while retrieving token. ', error);
    }
};

//obtainToken();

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Customize notification here
});